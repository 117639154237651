<template>
  <div class="chats">
    <div v-for="(data, index) in chatData" :key="index">
      <div class="chat" :class="{ 'chat-left': data.user_id !== '1' }">
        <div class="chat-avatar">
          <b-avatar
            v-if="data.user_id === 1"
            size="36"
            class="avatar-border-2 box-shadow-1"
            >A</b-avatar
          >
          <b-avatar v-else size="36" class="avatar-border-2 box-shadow-1">
            <feather-icon icon="UserIcon"
          /></b-avatar>
        </div>
        <div class="chat-body">
          <div class="chat-content">
            <p>{{ data.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue"

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style></style>
